import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

import SEO from '../components/SEO'
import HeroHeader from '../components/HeroHeader'
import { Phone } from '../components/ContactOptions'
import { LinkButton } from '../components/Button'

const title = 'Restaurant Stadlwirt - Die Riesenschnitzelfabrik'

const RestaurantPage = () => {
  const { fluidBackgroundImage } = useStaticQuery(
    graphql`
      query {
        fluidBackgroundImage: file(
          relativePath: { eq: "restaurant-hero.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  )

  return (
    <>
      <SEO title={title} />
      <HeroHeader
        title="Restaurant"
        subTitle="Die Riesenschnitzelfabrik"
        staticBackgroundImage="/uploads/restaurant-hero.jpg"
        fluidBackgroundImage={fluidBackgroundImage}
      />

      <section className="py-10">
        <div className="container flex flex-wrap items-center">
          <div className="w-full md:w-1/2 md:pr-5">
            <img
              src="/uploads/walter-lutz-kochmuetze.png"
              alt="Walter Lutz"
              className="rounded"
            />
          </div>
          <div className="w-full md:w-1/2 pt-5 md:pl-3 md:pt-0">
            <h2 className="text-2xl">Das Restaurant</h2>
            <hr className="my-2" />
            <p>
              Wir liegen unweit des Zentrums von Nauders. <br />
              Ein kleiner Spaziergang durch das 1.500 Seelendorf, vorbei an der
              Pfarrkirche, und einer Einkehr im Stadlwirt steht nichts mehr im
              Wege.{' '}
              <Link to="/anreise" className="golden">
                Die hauseigenen Parkplätze
              </Link>
              &nbsp; an der Nordseite des Restaurants stehen Ihnen zur
              Verfügung!
            </p>
          </div>
        </div>
      </section>

      <section className="bg-gray-900 text-white py-10">
        <div className="container flex flex-wrap items-center">
          <div className="w-full md:w-1/2 md:order-2 md:pl-5">
            <img
              src="/uploads/stadlwirt-riesen-wiener-schnitzel.jpg"
              alt="Stadlwirt Riesenschnitzel"
              className="rounded"
            />
          </div>

          <div className="w-full pt-5 md:w-1/2 pr-3 md:order-1 md:pt-0">
            <h2 className="text-2xl">Österreichs größtes Schnitzel!</h2>
            <hr className="my-2" />
            <p>
              Genießen Sie das größte Schnitzel Österreich´s im Stadlwirt oder
              bei Ihnen zu Hause! Zum Mitnehmen erhalten Sie einen speziellen
              Karton.
              <br />
              <br />
              Ihr Riesenschnitzel können Sie täglich&nbsp;
              <strong>
                ab 16 Uhr telefonisch unter &nbsp;
                <Phone className="golden" />
                &nbsp; bestellen
              </strong>{' '}
              und dann beim Stadlwirt abholen. Sie können natürlich auch einfach
              vorbeikommen und ein paar Minuten warten, bis der Stadlwirt Ihr
              Schnitzel zubereitet hat.
            </p>
          </div>
        </div>
      </section>

      <section className="py-10">
        <div className="container flex flex-wrap items-center">
          <div className="w-full md:w-1/2 md:pr-5">
            <img
              src="/uploads/vegetables.jpg"
              alt="Gemüse"
              className="rounded"
            />
          </div>
          <div className="w-full pt-5 md:w-1/2 md:pt-0">
            <h2 className="text-2xl">Nur die feinsten Zutaten</h2>
            <hr className="my-2" />
            <p>
              Um ihren Wünschen gerecht zu werden, wird bei der Zubereitung der
              Produkte großer Wert auf die Verwendung frischer Produkte gelegt.
              Dabei sind beste Qualität von Fleisch und Fisch eine
              Selbstverständlichkeit.
            </p>
            <LinkButton
              to="https://mobile.mister-digigast.at/stadlwirt/page-26"
              openInNewTab
              className="mt-2"
            >
              Speisekarte ansehen
            </LinkButton>
          </div>
        </div>
      </section>
    </>
  )
}

export default RestaurantPage
