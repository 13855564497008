import React from 'react'
import GatsbyBackgroundImage from 'gatsby-background-image'

import { FluidGatsbyImage } from '../typings/GatsbyImage'

const HeroContent = ({ title, subTitle }: HeroContentProps) => {
  return (
    <div className="text-center text-white">
      <h1 className="text-4xl">{title}</h1>
      {subTitle && <p className="text-xl">{subTitle}</p>}
    </div>
  )
}

interface HeroContentProps {
  title: string
  subTitle?: string
}

const FluidHeroHeader = ({
  title,
  subTitle,
  fluidBackgroundImage,
}: FluidHeroHeaderProps) => {
  return (
    <GatsbyBackgroundImage
      Tag="div"
      className="h-48 sm:h-56 md:h-64 lg:h-72 bg-gray-400 flex justify-center items-center"
      fluid={fluidBackgroundImage.childImageSharp.fluid}
      backgroundColor={`#1a202c`}
    >
      <HeroContent title={title} subTitle={subTitle} />
    </GatsbyBackgroundImage>
  )
}

interface FluidHeroHeaderProps extends HeroContentProps {
  fluidBackgroundImage: FluidGatsbyImage
}

const StaticHeroHeader = ({
  title,
  subTitle,
  staticBackgroundImage,
}: StaticHeroHeaderProps) => {
  const style: React.CSSProperties = staticBackgroundImage
    ? {
        backgroundImage: `url(${staticBackgroundImage})`,
      }
    : { backgroundColor: '#1a202c' }

  return (
    <div
      className="h-48 sm:h-56 md:h-64 lg:h-72 bg-gray-400 flex justify-center items-center bg-center bg-no-repeat bg-cover"
      style={style}
    >
      <HeroContent title={title} subTitle={subTitle} />
    </div>
  )
}

interface StaticHeroHeaderProps extends HeroContentProps {
  staticBackgroundImage?: string
}

const HeroHeader = ({
  title,
  subTitle,
  fluidBackgroundImage,
  staticBackgroundImage,
}: Props) => {
  if (fluidBackgroundImage)
    return (
      <FluidHeroHeader
        title={title}
        subTitle={subTitle}
        fluidBackgroundImage={fluidBackgroundImage}
      />
    )

  return (
    <StaticHeroHeader
      title={title}
      subTitle={subTitle}
      staticBackgroundImage={staticBackgroundImage}
    />
  )
}

interface Props extends HeroContentProps {
  fluidBackgroundImage?: FluidGatsbyImage
  staticBackgroundImage?: string
}

export default HeroHeader
