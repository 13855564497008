import React from 'react'
import { Link } from 'gatsby'

export const LinkButton = ({
  to,
  children,
  openInNewTab,
  ...props
}: LinkButtonProps &
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >) => {
  if (openInNewTab)
    return (
      <a href={to} target="_blank" rel="noopener noreferrer">
        <Button {...props}>{children}</Button>
      </a>
    )

  return (
    <Link to={to} target="_blank">
      <Button>{children}</Button>
    </Link>
  )
}

interface LinkButtonProps {
  to: string
  children: React.ReactNode
  openInNewTab?: boolean
}
const Button = ({
  children,
  className = '',
  ...props
}: React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) => {
  return (
    <button className={'btn btn-red' + ` ${className}`} {...props}>
      {children}
    </button>
  )
}

export default Button
